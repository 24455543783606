import axios from "axios";
import {getToken} from "./user/auth/Auth";
import moment from "moment/moment";

export const REACT_APP_BUILD = process.env.REACT_APP_BUILD;

export function build() {
    if (REACT_APP_BUILD) {
        return REACT_APP_BUILD;
    }
    return 'UNKNOWN';
}

export function isHome() {
    return window.location.pathname === "/";
}

export function restRoute() {

    const {hostname} = window.location

    switch (hostname) {
        case '127.0.0.1':
            return 'http://127.0.0.1:8071/rest'
        case 'localhost':
            return 'http://127.0.0.1:8071/rest'
    }

    return 'http://smartroster.club/rest'
}


export function env() {

    const {hostname} = window.location

    switch (hostname) {
        case 'smartroster.club':
            return 'PROD';
    }

    return 'DEV';
}

export function isDev() {
    return env() === 'DEV';
}

export function doRestCall(url, method, params = {}, data = {}, onSuccess = null, onError = null, onFinally = null) {
    axios.request({
        baseURL: restRoute(),
        url: url,
        method: method,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + getToken().value,
        },
        params: params,
        data: data,
    })
        .then((response) => {
            onSuccess && onSuccess(response.data);
        })
        .catch((error) => {
            console.log(error);
            onError && onError(error)
        })
        .finally(() => {
            onFinally && onFinally()
        });
}


export function doRestCallForFile(url, method, params = {}, data = {}, onSuccess = null, onError = null, onFinally = null) {
    axios.request({
        baseURL: restRoute(),
        url: url,
        method: method,
        responseType: 'blob',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + getToken().value,
        },
        params: params,
        data: data,
    })
        .then((response) => {
            onSuccess && onSuccess(response.data);
        })
        .catch((error) => {
            console.log(error);
            onError && onError(error)
        })
        .finally(() => {
            onFinally && onFinally()
        });
}

