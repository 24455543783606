import React, {useEffect, useState} from "react";
import {Auth} from "../../../user/auth/Auth";

import "./crusade-campaign-hq-styles.css"
import State from "./components/state/State";
import Alliances from "./components/alliances/Alliances";
import Players from "./components/players/Players";

const CampaignHq = () => {

    return (
        <>
            <Auth/>

            <div className="container c-c-hq-container">
                <div className="c-c-hq-name"><h2 className="">M2.025 - Vigilantia System</h2></div>

                <div className="m-5">
                    <div className="row c-c-hq-row"  >
                        <div className="">
                            <h6>Map</h6>
                            <div className=" c-c-hq-map-image">
                                <img
                                    src="https://e7.pngegg.com/pngimages/954/142/png-clipart-drawing-doodle-sketch-planet-miscellaneous-angle-thumbnail.png"
                                    width="100" height="100"/>
                                <img
                                    src="https://e7.pngegg.com/pngimages/954/142/png-clipart-drawing-doodle-sketch-planet-miscellaneous-angle-thumbnail.png"
                                    width="100" height="100"/>
                                <img
                                    src="https://e7.pngegg.com/pngimages/954/142/png-clipart-drawing-doodle-sketch-planet-miscellaneous-angle-thumbnail.png"
                                    width="100" height="100"/>
                                <img
                                    src="https://e7.pngegg.com/pngimages/954/142/png-clipart-drawing-doodle-sketch-planet-miscellaneous-angle-thumbnail.png"
                                    width="100" height="100"/>
                                <img
                                    src="https://e7.pngegg.com/pngimages/954/142/png-clipart-drawing-doodle-sketch-planet-miscellaneous-angle-thumbnail.png"
                                    width="100" height="100"/>
                            </div>
                        </div>
                    </div>
                    <State />

                    <div className="row c-c-hq-row">

                        <Players />
                    <Alliances />

                    </div>
                    <div className="row c-c-hq-row" >
                        <div className="col-lg-8">
                            <h6>Missions</h6>
                            <div className="c-c-hq-mission-row">
                                <div className="c-c-hq-text c-c-hq-mission-name">Valley of Death</div>
                                <div className="c-c-hq-text ">The attacker is en route to another place, but they have been ambushed. Now they must push through fire to escape it. The defender has to pin down these forces in a moment of weakness and if successful will stop an attack.</div>
                            </div>
                            <div className="c-c-hq-mission-row">
                                <div className="c-c-hq-text c-c-hq-mission-name">Unstable Reality</div>
                                <div className="c-c-hq-text ">Chaos and Necron forces have a hatred built into their very existence, as while the forces of Chaos seek to corrupt all in the galaxy to the worship of their Dark Gods, the soulless Necrons cannot praise them. Their old gods in the C’tan are an abomination in the eyes of the Chaos Gods. Chaos forces seek to claim the ground by holding rituals to invoke the power of their masters, while the Necrons will activate pylons to resist the forces of Chaos.
                                </div>
                            </div>

                            <div className="row c-c-hq-mission-row">
                                <div className="c-c-hq-text c-c-hq-mission-name">Tug of War</div>
                                <div className="c-c-hq-text ">One force is patrolling their borders and reinforcing their defences, but perhaps it is too little, too late as the aggressor seeks to break through and cause massive damage to the defender’s facility!</div>
                            </div>

                        </div>
                        <div className="col-sm-4 col-5">
                            <h6>Agendas</h6>
                            <div className="c-c-hq-agenda-row ">
                                <div className="c-c-hq-text c-c-hq-agenda-name">Sever The Head</div>
                                <div className="c-c-hq-text ">To slay one’s foes is not enough. It is required that you kill their leaders, for they espouse the ideas that drive them forward. Strike at the head and the heart will follow.</div>
                            </div>
                            <div className="c-c-hq-agenda-row ">
                                <div className="c-c-hq-text c-c-hq-agenda-name">No Escape</div>
                                <div className="c-c-hq-text ">aTo allow your opponent to flee from battle is to allow that foe to return home, lick their wounds, and sharpen their blades to face you again. Do not let your enemy know rest.</div>
                            </div>
                        </div>
                    </div>

                    <div className="row c-c-hq-row ">
                        <div className="col-sm-3 col-5">
                            <h6>Relics</h6>
                            <div className="c-c-hq-text c-c-hq-relics-row"><div className="c-c-hq-relics-cell">[Artificer]</div> <div className="c-c-hq-relics-cell">Talisman of Warding</div></div>
                            <div className="c-c-hq-text c-c-hq-relics-row"><div className="c-c-hq-relics-cell">[Artificer]</div> <div className="c-c-hq-relics-cell">Blurring Talisman</div></div>
                            <div className="c-c-hq-text c-c-hq-relics-row"><div className="c-c-hq-relics-cell">[Antiquity]</div> <div className="c-c-hq-relics-cell">Targeting Reticule</div></div>
                            <div className="c-c-hq-text c-c-hq-relics-row"><div className="c-c-hq-relics-cell">[Legendary]</div> <div className="c-c-hq-relics-cell">Bonding Studs</div></div>
                        </div>
                        <div className="col-sm-3 col-5">
                            <h6>Ranks</h6>
                            <div className="c-c-hq-text c-c-hq-ranks-row"><div className="c-c-hq-ranks-cell">[6-15]</div> <div className="c-c-hq-ranks-cell">Blooded</div></div>
                            <div className="c-c-hq-text c-c-hq-ranks-row"><div className="c-c-hq-ranks-cell">[16-30]</div> <div className="c-c-hq-ranks-cell">Battle-hardened</div></div>
                            <div className="c-c-hq-text c-c-hq-ranks-row"><div className="c-c-hq-ranks-cell">[31-50]</div> <div className="c-c-hq-ranks-cell">Heroic</div></div>
                            <div className="c-c-hq-text c-c-hq-ranks-row"><div className="c-c-hq-ranks-cell">[51+]</div> <div className="c-c-hq-ranks-cell">Legendary</div></div>

                        </div>
                        <div className="col-sm-3 col-5">
                            <h6>Battle Traits</h6>
                            <div className="c-c-hq-text c-c-hq-bt-row"><div className="c-c-hq-bt-cell">[Infantry]</div> <div className="c-c-hq-bt-cell">Piercing</div></div>
                            <div className="c-c-hq-text c-c-hq-bt-row"><div className="c-c-hq-bt-cell">[Infantry]</div> <div className="c-c-hq-bt-cell">Surprise Assault</div></div>
                            <div className="c-c-hq-text c-c-hq-bt-row"><div className="c-c-hq-bt-cell">[Vehicles]</div> <div className="c-c-hq-bt-cell">Arcane Defences</div></div>
                            <div className="c-c-hq-text c-c-hq-bt-row"><div className="c-c-hq-bt-cell">[Vehicles]</div> <div className="c-c-hq-bt-cell">Promotion</div></div>
                            <div className="c-c-hq-text c-c-hq-bt-row"><div className="c-c-hq-bt-cell">[Monsters]</div> <div className="c-c-hq-bt-cell">Extra Ammo</div></div>
                        </div>
                        <div className="col-sm-3 col-5">
                            <h6>Battle Scars</h6>
                            <div className="c-c-hq-text c-c-hq-bs-row"><div className="c-c-hq-bs-cell">[Infantry]</div> <div className="c-c-hq-bs-cell">Piercing</div></div>
                            <div className="c-c-hq-text c-c-hq-bs-row"><div className="c-c-hq-bs-cell">[Infantry]</div> <div className="c-c-hq-bs-cell">Surprise Assault</div></div>
                            <div className="c-c-hq-text c-c-hq-bs-row"><div className="c-c-hq-bs-cell">[Vehicles]</div> <div className="c-c-hq-bs-cell">Arcane Defences</div></div>
                            <div className="c-c-hq-text c-c-hq-bs-row"><div className="c-c-hq-bs-cell">[Vehicles]</div> <div className="c-c-hq-bs-cell">Promotion</div></div>
                            <div className="c-c-hq-text c-c-hq-bs-row"><div className="c-c-hq-bs-cell">[Monsters]</div> <div className="c-c-hq-bs-cell">Extra Ammo</div></div>
                        </div>
                    </div>

                    <div className="row c-c-hq-row" >
                        <div className="col-lg-8" >
                                <h6>Games</h6>
                                <div className="c-c-hq-players ">
                                    <div className="c-c-hq-games-name-header">Name</div>
                                    <div className="c-c-hq-games-winner-header">Winner</div>
                                    <div className="c-c-hq-games-date-header">Date</div>
                                </div>
                                <div className="c-c-hq-players ">
                                    <div className="c-c-hq-games-name ">test tournament 2. Round 2. user1 vs root</div>
                                    <div className="c-c-hq-games-winner ">Alex</div>
                                    <div className="c-c-hq-games-date ">06 Feb 2025</div>
                                </div>
                                <div className="c-c-hq-players ">
                                    <div className="c-c-hq-games-name ">test tournament 2. Round 2. user1 vs root</div>
                                    <div className="c-c-hq-games-winner ">Stevo</div>
                                    <div className="c-c-hq-games-date ">12 Mar 2025</div>
                                </div>
                                <div className="c-c-hq-players ">
                                    <div className="c-c-hq-games-name ">test tournament 2. Round 2. user1 vs root</div>
                                    <div className="c-c-hq-games-winner ">Velvetine</div>
                                    <div className="c-c-hq-games-date ">02 Jan 2025</div>
                                </div>
                                <div className="c-c-hq-players ">
                                    <div className="c-c-hq-games-name ">test tournament 2. Round 2. user1 vs root</div>
                                    <div className="c-c-hq-games-winner ">Stevo</div>
                                    <div className="c-c-hq-games-date ">16 Jun 2025</div>
                                </div>
                                <div className="c-c-hq-players ">
                                    <div className="c-c-hq-games-name ">test tournament 2. Round 2. user1 vs root</div>
                                    <div className="c-c-hq-games-winner ">Lucky</div>
                                    <div className="c-c-hq-games-date ">28 Nov 2025</div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CampaignHq;

