import './App.css';

import {BrowserRouter, Route, Router, Routes} from "react-router-dom";
import MainMenu from "./menu/MainMenu";
import {CurrentUserContextProvider, useCurrentUserContext} from "./user/CurrentUserContext";
import ReactGA from "react-ga4";
import Footer from "./home/footer/Footer";
import React, {useRef} from "react";
import RestUsage from "./system/restusage/RestUsage";
import Health from "./system/Health";
import Home from "./home/Home";

import GameCreate from "./game/create/GameCreate";
import Games from "./game/all/Games";
import GameView from "./game/view/GameView";
import GameEdit from "./game/edit/GameEdit";
import UserRegistration from "./user/register/UserRegistration";
import UsersAll from "./user/all/UsersAll";
import UserCreate from "./user/create/UserCreate";
import UserLogin from "./user/login/UserLogin";
import UserLogout from "./user/logout/UserLogout";
import UserAccount from "./user/account/UserAccount";
import TournamentCreate from "./tournament/create/TournamentCreate";
import Tournaments from "./tournament/all/Tournaments";
import TournamentView from "./tournament/view/TournamentView";
import TournamentBoard from "./tournament/board/TournamentBoard";
import TournamentEdit from "./tournament/edit/TournamentEdit";
import TagsMy from "./tag/my/TagsMy";
import AssignUnit from "./tag/assignunit/AssignUnit";
import ForceCreate from "./crusade/force/create/ForceCreate";
import ForceView from "./crusade/force/view/ForceView";
import ForceManage from "./crusade/force/manage/ForceManage";
import ForcePrint from "./crusade/force/print/ForcePrint";
import CardCreate from "./crusade/card/create/CardCreate";
import MyForces from "./crusade/force/my/MyForces";
import CardManage from "./crusade/card/manage/CardManage";

import Forces from "./crusade/force/all/Forces";
import NewsCreate from "./news/create/NewsCreate";
import NewsEdit from "./news/edit/NewsEdit";
import News from "./news/all/News";
import Landing from "./landing/Landing";
import Complete from "./system/stripe/Complete";
import Checkout from "./system/stripe/Checkout";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import Support from "./support/Support";
import CrusadeRosterView from "./crusade/roster/view/CrusadeRosterView";
import CrusadeRosterManage from "./crusade/roster/manage/CrusadeRosterManage";
import CrusadeRosterPrint from "./crusade/roster/print/CrusadeRosterPrint";
import Admin from "./admin/Admin";
import CodexUnits from "./admin/codex/unit/CodexUnits";
import Rosters from "./roster/all/Rosters";
import RosterView from "./roster/view/RosterView";
import RosterManage from "./roster/manage/RosterManage";
import {DataDogInit} from "./DataDog";
import CampaignHq from "./crusade/campaign/hq/CampaignHq";
import UserActivation from "./user/activation/UserActivation";


function App() {

    ReactGA.initialize("G-GG4M498TS6");

    const queryParams = new URLSearchParams(window.location.search)
    const ref = queryParams.get("ref")

    DataDogInit()

/*
    const stripePromise = loadStripe("pk_test_51QG41MRx7hxJyAyLFUS2fXlRCgmnYmL2wcHrMvUdUKy9mzLU0nXsJUmscCeywAZ2Pzqe5JBYq20hMEMLCGiGYntW00ouLbJgFp");
*/

    return (
        <div>
            <BrowserRouter>
                <CurrentUserContextProvider>

                    <MainMenu/>
{/*
                    <Elements stripe={stripePromise}>
*/}

                    <Routes>

                        <Route path="/system/usage/rest" element={<RestUsage/>}/>
                        <Route path="/system/health" element={<Health/>}/>

                        <Route path="/support" element={<Support />} />

                        <Route path="/checkout" element={<Checkout/>}/>
                        <Route path="/complete" element={<Complete/>}/>

                        <Route path="/home" element={<Home/>}/>
                        <Route path="/" element={<Landing/>}/>

                        <Route path="/roster/:rosterId/manage" element={<RosterManage/>}/>
                        <Route path="/roster/:rosterId/view" element={<RosterView/>}/>
                        <Route path="/rosters" element={<Rosters/>}/>


                        {/*
                        <Route path="/roster/create" element={<RosterCreate/>}/>
                        <Route path="/roster/edit/:rosterId" element={<RosterEdit/>}/>
                        <Route path="/rosters/all" element={<RostersAll/>}/>
                        <Route path="/rosters/my" element={<RostersMy/>}/>
                        <Route path="/roster/view/:rosterId" element={<RosterView/>}/>
*/}

                        <Route path="/game/create" element={<GameCreate/>}/>
                        <Route path="/games" element={<Games/>}/>
                        <Route path="/game/view/:gameId" element={<GameView/>}/>
                        <Route path="/game/edit/:gameId" element={<GameEdit/>}/>

                        <Route path="/user/registration" element={<UserRegistration/>}/>
                        <Route path="/users/all" element={<UsersAll/>}/>
                        <Route path="/user/create" element={<UserCreate/>}/>
                        <Route path="/user/login" element={<UserLogin/>}/>
                        <Route path="/user/logout" element={<UserLogout/>}/>
                        <Route path="/user/account" element={<UserAccount/>}/>
                        <Route path="/user/activation" element={<UserActivation/>}/>

                        <Route path="/tournament/create" element={<TournamentCreate/>}/>
                        <Route path="/tournaments" element={<Tournaments/>}/>
                        <Route path="/tournament/view/:tournamentId" element={<TournamentView/>}/>
                        <Route path="/tournament/board/:tournamentId" element={<TournamentBoard/>}/>
                        <Route path="/tournament/edit/:tournamentId" element={<TournamentEdit/>}/>

                        <Route path="/tags/my" element={<TagsMy/>}/>
                        <Route path="/tag/assign/unit" element={<AssignUnit/>}/>

                        <Route path="/codex/units" element={<CodexUnits/>}/>

                        <Route path="/admin" element={<Admin/>}/>

                        <Route path="/crusade/force/create" element={<ForceCreate/>}/>
                        <Route path="/crusade/force/:forceId/view" element={<ForceView/>}/>
                        <Route path="/crusade/force/:forceId/manage" element={<ForceManage/>}/>
                        <Route path="/crusade/force/:forceId/print" element={<ForcePrint/>}/>
                        <Route path="/crusade/force/:forceId/unit/create" element={<CardCreate/>}/>
                        <Route path="/crusade/forces/my" element={<MyForces/>}/>
                        <Route path="/crusade/forces" element={ <Forces /> }  />

                        <Route path="/crusade/card/create" element={<CardCreate/>}/>
                        <Route path="/crusade/force/:forceId/card/:cardId/manage" element={<CardManage/>}/>

                        <Route path="/crusade/force/:forceId/roster/:rosterId/view" element={<CrusadeRosterView/>}/>
                        <Route path="/crusade/force/:forceId/roster/:rosterId/manage" element={<CrusadeRosterManage/>}/>
                        <Route path="/crusade/force/:forceId/roster/:rosterId/print" element={<CrusadeRosterPrint/>}/>

                        <Route path="/crusade/campaign/hq" element={<CampaignHq/>}/>

                        <Route path="/news" element={<News />}/>
                        <Route path="/news/create" element={<NewsCreate />}/>
                        <Route path="/news/edit/:newsId" element={<NewsEdit />}/>

                    </Routes>
{/*
                    </Elements>
*/}

                    <Footer/>

                </CurrentUserContextProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
