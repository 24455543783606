import React, {useEffect, useState} from "react";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import {Analytics} from "../../GoogleAnalytics";
import {doRestCall} from "../../AppUtils";

import './styles-g-all.css'
import Spinner from "../../_components/Spinner";
import Pagination from "../../home/components/Pagination";
import {Modal, Space} from "antd";

const Games = () => {

    Analytics("/games", "Games")

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [games, setGames] = useState([]);

    const [created, setCreated] = useState(false);
    const [master, setMaster] = useState(false);
    const [participated, setParticipated] = useState(false);
    const [all, setAll] = useState(true);

    const [selectedPage, setSelectedPage] = useState(1)
    const [pagination, setPagination] = useState({})

    const [isModalOpen, setModalOpen] = useState(false)
    const [newGameName, setNewGameName] = useState("")
    const [newGameError, setNewGameError] = useState()


    function onOk() {
        if (newGameName === null || newGameName === undefined || newGameName === "") {
            setNewGameError("Name is required")
            return
        }

        doRestCall('/game/create', 'post', null, {
            name: newGameName
        }, (response) => {
            setNewGameName("")
            setNewGameError("")
            setModalOpen(false)
            navigate("/game/edit/" + response.body.id)
        })
    }

    function onCancel() {
        setNewGameName("")
        setNewGameError("")
        setModalOpen(false)
    }

    function onCreate(e) {
        e.preventDefault()
        setModalOpen(true)
    }


    function onSuccess(response) {
        setGames(response.body)
        setPagination(response.pagination)
        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        doRestCall( '/games', 'get', {
            created: created,
            master: master,
            participated: participated,
            all: all,
            currentPage: selectedPage
        }, null, onSuccess, null,
            () => setLoading(false))
    }, [created, master, participated, all, selectedPage])

    function view(id) {
        navigate('/game/view/' + id)
    }

    function onCreated(e) {
        e.preventDefault();
        setAll(false)
        setParticipated(false)
        setMaster(false)
        setCreated(true)
        setSelectedPage(1)
    }

    function onMaster(e) {
        e.preventDefault();
        setAll(false)
        setParticipated(false)
        setMaster(true)
        setCreated(false)
        setSelectedPage(1)
    }

    function onParticipated(e) {
        e.preventDefault();
        setAll(false)
        setParticipated(true)
        setMaster(false)
        setCreated(false)
        setSelectedPage(1)
    }

    function onAll(e) {
        e.preventDefault();
        setAll(true)
        setParticipated(false)
        setMaster(false)
        setCreated(false)
        setSelectedPage(1)
    }

    return (
        <>
            <Auth />

            <Modal title="Create Game" open={isModalOpen} onOk={() => onOk()} onCancel={() => onCancel()}>
                {newGameError && <p>{newGameError}</p>}
                <Space direction="horizontal">
                    <label>Name</label>
                    <input type="text" placeholder="Name" onChange={(e) => setNewGameName(e.target.value)}/>
                </Space>
            </Modal>

            <Spinner isLoading={loading}>
                    <div className="row d-flex justify-content-center mt-5">
                        <div className="col-md-11">
                            <div className="row mb-3 justify-content-between">
                                <div className="col-auto">
                                    <button className={all ? "g-all-tlist-btn-active" : "g-all-tlist-btn" } onClick={(e) => onAll(e)}>ALL</button>
                                    <button className={created ? "g-all-tlist-btn-active" : "g-all-tlist-btn" } onClick={(e) => onCreated(e)}>Created By Me</button>
                                    <button className={master ? "g-all-tlist-btn-active" : "g-all-tlist-btn" } onClick={(e) => onMaster(e)}>Im Master</button>
                                    <button className={participated ? "g-all-tlist-btn-active" : "g-all-tlist-btn" } onClick={(e) => onParticipated(e)}>Im Participating</button>
                                </div>
                                <div className="col-auto">
                                <button className="g-all-tlist-btn" onClick={(e) => onCreate(e)}>Create Game</button>
                                </div>
                            </div>

                            <div className="g-all-vtable-container">
                                <table className="g-all-vtable">
                                    <thead className="g-all-vtable-head">
                                    <tr className="g-all-vtable-row">
                                        <th className="g-all-vtable-header">Name</th>
                                        <th className="g-all-vtable-header">Master</th>
                                        <th className="g-all-vtable-header">Date</th>
                                    </tr>
                                    </thead>
                                    <tbody className="g-all-vtable-body">
                                    {games?.map(game =>
                                        <tr className="g-all-vtable-row">
                                            <td className="g-all-vtable-cell g-all-vtable-cell-name"><a
                                                onClick={() => view(game.id)}>{game.name}</a></td>
                                            <td className="g-all-vtable-cell">{game.gameMaster?.username}</td>
                                            <td className="g-all-vtable-cell g-all-vtable-cell-date">{game.dateTime && moment(game.dateTime).format('DD MMM YYYY')}</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                                <Pagination pagination={pagination} selectedPage={setSelectedPage} />
                            </div>
                        </div>
                    </div>
            </Spinner>
        </>
    );
};

export default Games;

